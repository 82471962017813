<template>
  <b-modal
    id="agent-madal"
    ref="agent-madal"
    hide-footer
    title="ตั้งค่าเอเย่นต์"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label="รหัสเอเย่นต์"
      >
        <b-form-input
          v-model="input.item_name"
          type="text"
          placeholder="รหัสเอเย่นต์"
          required
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import AgentService from '@/services/AgentService'
import Swal from 'sweetalert2'

export default {
  name: 'OtherItemModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      input: {},
      isProcess: false,
      needReload: false
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
    },
    showModal() {
      this.$refs['agent-madal'].show()
    },
    hideModal() {
      this.$refs['agent-madal'].hide()
    },
    handleSubmit() {
      console.log(JSON.stringify(this.input))
      if(this.input?._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      AgentService.save(this.input)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    update() {
      AgentService.update(this.input._id, this.input)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#agent-madal {
  input[type=text] {
    color: blue;
  }
}
</style>
