<template>
  <div>
    <div class="row no-gutters">

      <div class="col-md-4 p-1" v-for="(item, index) in items" :key="index">
        <div class="card m-0">
          <div class="card-header p-1">
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-success">{{ item.item_name }}</span>
              <button class="btn btn-sm btn-link py-0" @click="addAgent(item._id)">เพิ่ม</button>
            </div>
          </div>
          <div class="card-body p-0">
            <table class="table table-hover m-0">
              <tbody>
                <tr v-for="(ag, agIndex) in item.agents">
                  <td class="p-1">
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{ ag.item_name }}</span>
                      <button class="btn btn-sm btn-link py-0" @click="editAgent(ag)">แก้ไข</button>
                    </div>
                  </td>
                </tr>
                <tr v-if="!item.agents.length">
                  <td class="text-center">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>

    <AgentModal :data="data" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import TransactionService from '@/services/TransactionService'
import AgentService from '@/services/AgentService'
import AgentModal from './components/AgentModal'

const defaultData = {
  _id: null,
  item_type: 'Agent',
  item_name: '',
  owners: {
    webId: null
  }
}

export default {
  name: 'BranchSettingAgent',
  components: {
    AgentModal
  },
  data() {
    return {
      Websites: [],
      Agents: [],
      isShowModal: false,
      data: JSON.parse(JSON.stringify(defaultData)),
    }
  },
  computed: {
    items() {
      return this.Websites.map((web)=>{
        web.agents = this.Agents.filter((ag)=>{
          return ag.owners.webId === web._id
        })
        return web
      })
    }
  },
  methods: {
    getWebsites() {
      TransactionService.gets('Website').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Websites = response.data
        }
      })
    },
    getAgents() {
      AgentService.gets().then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Agents = response.data
        }
      })
    },
    addAgent(webId) {
      this.data = JSON.parse(JSON.stringify({
        ...defaultData,
        owners: {
          webId: webId
        }
      }))
      this.isShowModal = true
    },
    editAgent(item) {
      this.data = {
        ...defaultData,
        ...item
      }
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      console.log('needReload', needReload)
      if(needReload) {
        this.getAgents()
      }
    },
  },
  mounted() {
    this.getWebsites()
    this.getAgents()
  }
}
</script>
